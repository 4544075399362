var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.value.length
    ? _c("el-row", { staticClass: "pg-2", attrs: { justify: "center" } }, [
        _vm._v("\n  " + _vm._s(_vm.$t("errors.emptyData")) + "\n"),
      ])
    : _c(
        "div",
        { staticClass: "car-engine-list" },
        _vm._l(_vm.fuelTypesAndCapacitiesList, function (list, fuelId) {
          return _c("el-row", { key: fuelId }, [
            _c("div", { staticClass: "mr-label" }, [
              _vm._v(
                _vm._s(fuelId ? _vm.$t("carFuelTypes." + fuelId) + ":" : "")
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "mr-list" },
              _vm._l(list, function (item) {
                return _c(
                  "li",
                  { key: item.id, staticClass: "mr-list-item" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "mw-unset",
                        attrs: {
                          size: "mini",
                          type: _vm.isActive(item) ? "primary" : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("set-item", item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.capacity) + "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }