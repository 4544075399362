<template>
  <div class="cars-list">
    <el-row v-if="!value.length" class="pg-2 mr-empty-data" justify="center">
      {{ $t('errors.emptyData') }}
    </el-row>

    <ul v-else>
      <li v-for="(car, index) in value" :key="`${car.id}_${index}`">
        <TheTooltip
          :content="car._modificationId ? null : $t('message.editModification')"
        >
          <component
            :is="`CarItem${isMobile ? 'M' : ''}`"
            :show-options="showOptions"
            :value="car"
            :hide-all-options="hideAllOptions"
            @click.native="checkAndEmit(car)"
            @car-edit="$emit('car-edit', $event)"
            @remove="$emit('remove', car)"
            @to-vin-request="$emit('to-vin-request', $event)"
          />
        </TheTooltip>
      </li>
    </ul>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'CarsList',

  components: {
    /* eslint-disable */
    CarItem: () => import(/* webpackChunkName: "CarItem" */ './CarItem'),
    CarItemM: () => import(/* webpackChunkName: "CarItemM" */ './CarItemM')
    /* eslint-enable */
  },

  props: {
    hideAllOptions: Boolean,
    isMobile: Boolean,
    showOptions: Boolean,
    value: { type: Array, required: true }
  },

  methods: {
    // TODO clear it
    checkAndEmit (car) {
      if (car._modificationId) {
        this.$emit('set-item', car);
      }
    }
  }
};
</script>

<style scoped lang="scss">
ul, li {
  width: 100%;
  overflow: visible;
}

.cars-list {
  background-color: $white;
  overflow: visible;

  .car-item {
    cursor: pointer;
    border: 1px solid $grey-300;
    border-bottom: 0;
    position: relative;

    ::v-deep {
      .info {
        padding: 10px;
      }

      .selection-requests {
        padding-top: -10px;
      }
    }

    &:last-child {
      border-bottom: 1px solid $grey-300;
    }

    &:hover {
      background-color: $--menu-item-hover-fill;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.mr-empty-data {
  border: 1px solid $grey-300;
}
</style>
