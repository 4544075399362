<template>
  <el-row v-if="!value.length" class="pg-2" justify="center">
    {{ $t('errors.emptyData') }}
  </el-row>

  <div v-else class="car-engine-list">
    <el-row v-for="(list, fuelId) in fuelTypesAndCapacitiesList" :key="fuelId">
      <div class="mr-label">{{ fuelId ? $t(`carFuelTypes.${fuelId}`) + ':' : '' }}</div>

      <ul class="mr-list">
        <li v-for="item in list" :key="item.id" class="mr-list-item">
          <el-button
            class="mw-unset"
            size="mini"
            :type="isActive(item) ? 'primary' : ''"
            @click="$emit('set-item', item)"
          >
            {{ item.capacity }}
          </el-button>
        </li>
      </ul>
    </el-row>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy';
import CarDefaultList from './CarDefaultList';

export default {
  name: 'CarEngineList',

  extends: CarDefaultList,

  computed: {
    fuelTypesAndCapacitiesList () {
      return groupBy(this.value, item => item.fuel_type);
    }
  }
};
</script>
