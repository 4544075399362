<template>
  <div class="car-years-list">
    <el-row v-if="!value.length" class="pg-2" justify="center">
      {{ $t('errors.emptyData') }}
    </el-row>

    <template v-else>
      <ul
        v-for="(list, index) in yearsList"
        :key="index"
        class="mr-list"
      >
        <li v-for="item in list" :key="item.id" class="mr-list-item">
          <el-button
            size="mini"
            :type="isActive(item) ? 'primary' : ''"
            @click="$emit('set-item', item)"
          >
            {{ item.name }}
          </el-button>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import CarDefaultList from './CarDefaultList';

export default {
  name: 'CarYearsList',

  extends: CarDefaultList,

  computed: {
    yearsList () {
      const listByOrder = orderBy(this.value, 'id');
      const years = groupBy(listByOrder, year => Number.parseInt(year.name / 10));

      return Object.keys(years)
        .reverse()
        .map(yearPrefix => years[yearPrefix]);
    }
  }
};
</script>
