var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "car-selector" },
    [
      _vm.isLoading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.isLoading,
                expression: "isLoading",
              },
            ],
            staticClass: "loader-block",
          })
        : _c(_vm.listName, {
            tag: "component",
            attrs: { "is-active": _vm.isActiveItem, value: _vm.listValue },
            on: { "set-item": _vm.setItem },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }