var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cars-list" },
    [
      !_vm.value.length
        ? _c(
            "el-row",
            { staticClass: "pg-2 mr-empty-data", attrs: { justify: "center" } },
            [_vm._v("\n    " + _vm._s(_vm.$t("errors.emptyData")) + "\n  ")]
          )
        : _c(
            "ul",
            _vm._l(_vm.value, function (car, index) {
              return _c(
                "li",
                { key: car.id + "_" + index },
                [
                  _c(
                    "TheTooltip",
                    {
                      attrs: {
                        content: car._modificationId
                          ? null
                          : _vm.$t("message.editModification"),
                      },
                    },
                    [
                      _c("CarItem" + (_vm.isMobile ? "M" : ""), {
                        tag: "component",
                        attrs: {
                          "show-options": _vm.showOptions,
                          value: car,
                          "hide-all-options": _vm.hideAllOptions,
                        },
                        on: {
                          "car-edit": function ($event) {
                            return _vm.$emit("car-edit", $event)
                          },
                          remove: function ($event) {
                            return _vm.$emit("remove", car)
                          },
                          "to-vin-request": function ($event) {
                            return _vm.$emit("to-vin-request", $event)
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.checkAndEmit(car)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }