<template>
  <div class="car-selector">
    <div v-if="isLoading" v-loading="isLoading" class="loader-block" />

    <component
      :is="listName"
      v-else
      :is-active="isActiveItem"
      :value="listValue"
      @set-item="setItem"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import cloneDeep from 'lodash/cloneDeep';
import transform from 'lodash/transform';
import get from 'lodash/get';

import CarsList from './CarsList';
import CarDefaultList from './CarDefaultList';
import CarYearsList from './CarYearsList';
import CarEngineList from './CarEngineList';
import { getDefault, library } from '@/lib/core';

const listNames = {
  modification: 'CarsList',
  production_year: 'CarYearsList',
  fuel_types_and_capacitie: 'CarEngineList'
};

export default {
  name: 'CarSelector',

  components: {
    CarDefaultList,
    CarYearsList,
    CarEngineList,
    CarsList
  },

  props: {
    getParsed: { type: Function, default: i => i },
    defaultData: { type: Object, default: () => ({}) },
    useDefault: Boolean,
    mobileSize: { type: String, default: 'sm' }
  },

  data () {
    const catalogFilter = getDefault('carModificationFilter');
    const menuItems = library.carModificationFilterTabs;
    const collection = transform(menuItems, (acc, key) => {
      acc[`${key}s`] = [];
    }, {});

    return {
      isLoading: true,
      menuItems,
      activeMenuItem: 'brand',
      collection,
      activeList: [],
      formData: catalogFilter
    };
  },

  computed: {
    listName () {
      return listNames[this.activeMenuItem] || 'CarDefaultList';
    },

    listValue () {
      return this.activeMenuItem === 'fuel_types_and_capacitie'
        ? this.collection.fuel_types_and_capacities
        : this.activeList;
    },

    disabled () {
      return {
        model: !this.formData.brand,
        production_year: !this.formData.model,
        body_type: !this.formData.production_year,
        fuel_types_and_capacitie: !this.formData.body_type,
        drive_type: !this.formData.fuel_type || !this.formData.capacity,
        modification: !this.formData.drive_type
      };
    }
  },

  watch: {
    activeMenuItem: {
      immediate: true,
      handler (activeMenuItem) {
        this.query = '';
        this.emitTitle(activeMenuItem);
      }
    }
  },

  created () {
    this.countTab();
  },

  methods: {
    countTab () {
      const nextTabName = this.useDefault
        ? this.setDefaultData() || 'brand'
        : 'brand';

      if (!this.maxmq(this.mobileSize)) {
        this.toggleMenu(nextTabName, { isDefault: this.useDefault });
      }
    },

    setDefaultData () {
      if (isEmpty(Object.values(this.defaultData).filter(Boolean))) {
        return '';
      }

      this.isLoading = false;

      ['brand', 'model', 'production_year'].forEach((name) => {
        const listName = `${name}s`;
        let list = this.defaultData[listName] || [];

        if (name === 'production_year') {
          list = this.getParsedYears(this.defaultData.production_years);
        }

        this.collection[listName] = list;
        this.formData[name] = typeof this.defaultData[name] === 'object'
          ? this.defaultData[name]?.id || null
          : this.defaultData[name] || null;
      });

      if (this.defaultData.production_years) {
        this.activeList = this.collection.production_years;
      } else {
        this.activeList = this.defaultData.models ||
          this.defaultData.brands ||
          [];
      }

      if (this.formData.production_year) {
        return 'body_type';
      } else if (this.formData.model) {
        this.activeMenuItem = !this.maxmq(this.mobileSize) ? 'production_year' : '';
        return 'production_year';
      } else if (this.formData.brand) {
        return 'model';
      } else {
        return 'brand';
      }
    },

    async toggleMenu (name, options = {}) {
      if (!name) {
        return null;
      }

      if (!options.isDefault) {
        this.isLoading = true;
      }

      const listName = `${name}s`;

      try {
        const getF = this[`get${upperFirst(listName)}`];
        const list = await getF({ limit: 0 }, options);

        this.collection[listName] = list || [];
      } catch (e) {
        this.collection[listName] = [];
        console.error(e);
      }

      if (options.isDefault && this.defaultData[name]) {
        const _item = this.collection[listName]
          .find(({ id }) => id === this.defaultData[name]);

        if (_item) {
          this.activeMenuItem = name;
          return this.setItem(_item, options);
        } else {
          this.activeList = this.collection[listName];
          this.isLoading = false;
        }
      } else if (
        !this.formData[name] &&
        !options.passOne &&
        !options.isDefault &&
        this.collection[listName].length === 1
      ) {
        this.activeMenuItem = name;
        return this.setItem(this.collection[listName][0]);
      } else {
        this.activeList = this.collection[listName];

        this.$nextTick(() => {
          this.activeMenuItem = name;
          this.isLoading = false;
        });
      }
    },

    setItem (item, option) {
      if (this.activeMenuItem === 'fuel_types_and_capacitie') {
        this.formData.capacity = item.capacity;
        this.formData.fuel_type = item.fuel_type;
      } else if (this.activeMenuItem === 'modification') {
        return this.saveModificationId(item);
      } else {
        this.formData[this.activeMenuItem] = item.id;
      }

      return this.setNextMenu(option);
    },

    setNextMenu (options = {}) {
      const index = this.menuItems
        .findIndex(name => name === this.activeMenuItem);

      const nextMenu = this.menuItems[index + 1];

      if (nextMenu) {
        this.clearNextMenuItems(index + 1);
        return this.toggleMenu(nextMenu, options);
      }
    },

    clearNextMenuItems (index) {
      for (let i = index, l = this.menuItems.length; i < l; i++) {
        if (this.menuItems[i] === 'fuel_types_and_capacitie') {
          this.formData.capacity = null;
          this.formData.fuel_type = null;
        } else {
          this.formData[this.menuItems[i]] = null;
        }
      }
    },

    saveModificationId (item) {
      this.$emit('select', { item, formData: this.formData });
      this.isLoading = true;
      this.$emit('title:update', { title: '', isBackspace: false });
      // this.clearFilter();
    },

    async getBrands (params, options) {
      const brands = get(this, 'defaultData.brands');

      if (options.isDefault && !isEmpty(brands)) {
        return cloneDeep(brands);
      }

      const { results } = await this.$store.dispatch(
        'cache/get',
        { action: 'carBrands/fetchBrands' }
      );

      return cloneDeep(results);
    },

    async getModels (params, options) {
      const models = get(this, 'defaultData.models');

      if (options.isDefault && !isEmpty(models)) {
        return models;
      }

      const _params = { brand: this.formData.brand, ...params };
      const { results } = await this.$store.dispatch(
        'cache/get',
        { action: 'carModels/getModels', payload: _params }
      );

      return results;
    },

    async getProduction_years (params, options) {
      const productionYears = get(this, 'defaultData.production_years');

      if (options.isDefault && !isEmpty(productionYears)) {
        return this.getParsedYears(productionYears);
      }

      const { brand, model } = this.formData;
      const _params = { brand, model, ...params };

      const { production_years } = await this.$store.dispatch(
        'cache/get',
        { action: 'carModifications/getModifications', payload: _params }
      );

      return this.getParsedYears(production_years);
    },

    async getBody_types (params) {
      const { brand, model, production_year } = this.formData;
      const _params = { brand, model, production_year, ...params };

      const { body_types } = await this.$store.dispatch(
        'cache/get',
        { action: 'carModifications/getModifications', payload: _params }
      );

      return body_types.map(id => ({ id, name: this.$t(`carBodyTypes.${id}`) }));
    },

    async getFuel_types_and_capacities (params) {
      const { brand, model, production_year, body_type } = this.formData;
      const _params = { brand, model, production_year, body_type, ...params };

      const { fuel_types_and_capacities } = await this.$store.dispatch(
        'cache/get',
        { action: 'carModifications/getModifications', payload: _params }
      );

      return fuel_types_and_capacities.map((val) => {
        const name = this.$t(`carFuelTypes.${val[0]}`);
        const fuel_type = val[0];
        const capacity = val[1];

        return {
          id: `${fuel_type}-${capacity}`,
          name: `${name} (${capacity})`,
          fuel_type,
          capacity
        };
      });
    },

    async getDrive_types (params) {
      const {
        brand,
        model,
        production_year,
        body_type,
        fuel_type,
        capacity
      } = this.formData;
      const _params = {
        brand,
        model,
        production_year,
        body_type,
        fuel_type,
        capacity,
        ...params
      };

      const { drive_types } = await this.$store.dispatch(
        'cache/get',
        { action: 'carModifications/getModifications', payload: _params }
      );

      return drive_types.map(id => ({
        id,
        name: this.$t(`carDriveTypes.${id}`)
      }));
    },

    async getModifications () {
      try {
        const { modification, ...formData } = this.formData;

        const { results } = await this.$store.dispatch(
          'cache/get',
          { action: 'carModifications/getModifications', payload: formData }
        );

        return (results || []).map(this.getParsed);
      } catch (e) {
        console.error(e);
        return [];
      }
    },

    getParsedYears (years) {
      return transform(years, (result, value) => {
        result.push({ id: value, name: value });
      }, []);
    },

    getTitleBy (name) {
      const list = this.collection[`${name}s`] || [];
      let item = null;

      if (name === 'fuel_types_and_capacitie') {
        const id = `${this.formData.fuel_type}-${this.formData.capacity}`;
        item = list.find(item => id === item.id);
      } else {
        item = list.find(({ id }) => {
          return this.formData[name] && typeof this.formData[name] === 'object'
            ? id === this.formData[name]?.id
            : id === this.formData[name];
        });
      }

      return item ? String(item.name) : '';
    },

    isActiveItem ({ id, fuel_type, capacity }) {
      if (this.activeMenuItem === 'fuel_types_and_capacitie') {
        return this.formData.fuel_type === fuel_type &&
          this.formData.capacity === capacity;
      }

      return this.formData[this.activeMenuItem] === id;
    },

    clearFilter () {
      this.formData = getDefault('carModificationFilter');
      this.toggleMenu('brand');
    },

    goBack () {
      const index = this.menuItems.findIndex(it => it === this.activeMenuItem);

      if (index > 0) {
        this.clearNextMenuItems(index - 1);
        this.toggleMenu(this.menuItems[index - 1], { passOne: true });
      }
    },

    emitTitle (name) {
      const index = this.menuItems.findIndex(it => it === name);

      this.$emit(
        'title:update',
        {
          title: this.getSelectorTitle(name),
          isBackspace: index > 0,
          prefix: index > 0 ? this.$t(`garage.selectTitles.${name}`) : ''
        }
      );
    },

    getSelectorTitle (name) {
      const acc = this.menuItems.reduce((acc, _name) => {
        if (_name === 'fuel_types_and_capacitie' && this.formData.fuel_type) {
          acc.push(this.getTitleBy('fuel_types_and_capacitie'));
        } else if (this.formData[_name]) {
          acc.push(this.getTitleBy(_name));
        }

        return acc;
      }, []);

      if (!acc.length) {
        return this.$t(`garage.selectTitles.${name}`);
      } else if (acc.length <= 3) {
        return `${acc.join(' ')}. ${this.$t(`garage.selectTitles.${name}`)}`.trim();
      } else if (acc.length === 4) {
        const str = [...acc.slice(0, 3).join(' '), ', ', ...acc.slice(-1)].join('');
        return `${str}. ${this.$t(`garage.selectTitles.${name}`)}`.trim();
      } else if (acc.length > 4) {
        const start = acc.slice(0, 3).join(' ');
        const [end] = acc.slice(-1);
        const prefix = this.$t(`garage.selectTitles.${name}`);
        return [start, ', ...,', ...end, '. ', prefix].join('');
      }

      return acc.push(this.$t(`garage.selectTitles.${name}`)).join(', ');
    }
  }
};
</script>

<style scoped lang="scss">
.loader-block {
  min-height: 200px;
  width: 100%;
}
</style>
